import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../environments/environment';

interface LoginResponse {
  access_token: string;
}

export interface AuthUserResponse {
  data: any;
  user: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.backendApiUrl + 'auth/token';

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  // build a unique cookie name because we have multiple environments that share the same domain
  private getCookieName(cookieName: string): string {
    const cookiePrefix = environment.environmentName + '_';
    return cookiePrefix + cookieName;
  }

  // save the JWT token to a cookie for the whole domain that expires in 1 year
  setToken(token: string): void {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    const domain = environment.cookieDomain;
    const cookieName = this.getCookieName('auth_token');
    this.cookieService.set(cookieName, token, expirationDate, '/', domain);
  }

  // get the JWT token from the cookie
  getToken(): string | null {
    const cookieName = this.getCookieName('auth_token');
    return this.cookieService.get(cookieName);
  }

  // send login request to backend and call setToken() if response is received
  login(username: string, password: string): Observable<LoginResponse> {
    const loginData = { username, password };
    return this.http.post<LoginResponse>(this.apiUrl, loginData).pipe(
      tap(response => {
        const token = response.access_token;
        if (token) {
          this.setToken(token);
        }
      })
    );
  }

  // check if auth_user cookie is set
  isAuthUserSet(): boolean {
    const cookieName = this.getCookieName('auth_user');
    return this.cookieService.check(cookieName);
  }

  // set auth_user cookie
  setAuthUser(user: any): void {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    const domain = environment.cookieDomain;
    const cookieName = this.getCookieName('auth_user');
    this.cookieService.set(cookieName, JSON.stringify(user), expirationDate, '/', domain);
  }

  // send request to get user profile from backend and call setAuthUser() if response is received
  getAuthUser(): Observable<AuthUserResponse> {
    const authToken = this.getToken();

    if (!authToken) {
      return new Observable<AuthUserResponse>((observer) => {
        observer.error(new Error('Authentication Token not found.'));
      });
    }

    const headers = {
      Authorization: `Bearer ${authToken}`
    };

    return this.http.get<AuthUserResponse>(`${environment.backendApiUrl}users/me-basic`, { headers }).pipe(
      tap(response => {
        const user = response.data.user;
        if (user) {
          this.setAuthUser(user);
        }
      })
    );
  }
}
