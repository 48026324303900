import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  redirectMessage: string | null = null;
  redirectUrl: string | null = null;

  loginForm: FormGroup = this.formBuilder.group({
    username: [null, Validators.required],
    password: [null, Validators.required]
  });

  loginStatus: boolean | null = null;
  loginOutput: string | null = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.redirectUrl = params.get('redirect');
      this.redirectMessage = params.get('message');
    });
  }

  // handle login form, all of the real magic happens in auth service
  onSubmit(): void {
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;

    this.authService.login(username, password).pipe(
      catchError((error) => {
        this.loginStatus = false;
        this.loginOutput = 'Error: ' + error.message;
        return [];
      })
    ).subscribe((response) => {
      if (response && response.access_token) {
        this.authService.getAuthUser().pipe(
          catchError((error) => {
            this.loginStatus = false;
            this.loginOutput = 'Error: ' + error.message;
            return [];
          })
        ).subscribe(() => {
          if (this.authService.isAuthUserSet()) {
            if (!this.redirectUrl) {
              this.redirectUrl = environment.dataMapperUrl;
            }
            this.loginStatus = true;
            this.loginOutput = 'Redirecting ...';

            if (this.redirectUrl !== null) {
              setTimeout(() => {
                window.location.href = this.redirectUrl!;
              }, 0);
            }
          }
        });
      }
    });
  }
}
