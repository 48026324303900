<div class="mat-elevation-z2 login-container">
  <div class="logo-container">
    <img class="logo" src="assets/images/logo.png" alt="PVBid Logo">
  </div>
  <p *ngIf="redirectMessage" class="message">{{ redirectMessage }}</p>
  <div class="form-container">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="mat-form">
      <input type="hidden" name="redirect" [value]="redirectUrl">
      <div class="form-row">
        <mat-form-field appearance="fill">
          <input matInput placeholder="Username" type="text" formControlName="username" id="username" required>
          <mat-error *ngIf="loginForm.get('username')?.hasError('required')">Username is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="fill">
          <input matInput placeholder="Password" type="password" formControlName="password" id="password" required>
          <mat-error *ngIf="loginForm.get('password')?.hasError('required')">Password is required</mat-error>
        </mat-form-field>
      </div>
      <button mat-raised-button color="accent" type="submit" [disabled]="loginForm.invalid">Login</button>
    </form>
    <div class="status-messages-container">
      <mat-card *ngIf="loginStatus !== null" class="mat-form-status" [ngClass]="{ 'success': loginStatus, 'error': !loginStatus }">
        {{ loginStatus ? 'Login Successful: ' + loginOutput : 'Login Error: ' + loginOutput }}
      </mat-card>
    </div>
  </div>
</div>
